import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import img from "../assets/new-icon.png";
import { FaBars } from "react-icons/fa";

const Navbar = ({ toggle, setOpenModal }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <NavbarWrapper scrollNav={scrollNav}>
      <NavbarContainer>
        <NavbarLogo to="/">
          <NavbarLogoContainer>
            <img src={img} alt="huginn_logo" />
          </NavbarLogoContainer>
        </NavbarLogo>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavbarList>
          <NavbarItem>
            <NavLinks href="#networks">Networks</NavLinks>
          </NavbarItem>
          <NavbarItem>
            <NavLinks href="#security">Security</NavLinks>
          </NavbarItem>
          <NavbarItem>
            <NavLinks href="#services">Services</NavLinks>
          </NavbarItem>
          {/* <NavbarItem
            id="tooltip"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHovered={isHovered}
          >
            <span id="tooltipText">Coming Soon!</span>
            <NavLinks
              href="https://medium.com/"
              target={"_blank"}
              style={{ border: "none", pointerEvents: "none" }}
            >
              Blog
            </NavLinks>
          </NavbarItem> */}
          <NavbarItem>
            <NavLinks
              href="https://medium.com/huginnacademy"
              target={"_blank"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>Blog</div>{" "}
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "16px" }}
              >
                open_in_new
              </span>
            </NavLinks>
          </NavbarItem>
          <NavbarItem>
            <NavLinks
              href="https://docs.huginn.tech/"
              target={"_blank"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>Docs</div>{" "}
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "16px" }}
              >
                open_in_new
              </span>
            </NavLinks>
          </NavbarItem>
          {/* <NavbarItem>
            <NavLinks href="#about">About</NavLinks>
          </NavbarItem> */}

          <NavbarItem>
            <NavLinks href="#contact">Contact</NavLinks>
          </NavbarItem>
        </NavbarList>
        <NavbarBtnWrapper>
          <button onClick={() => setOpenModal(true)}>Calculator</button>
        </NavbarBtnWrapper>
      </NavbarContainer>
    </NavbarWrapper>
  );
};

const NavbarWrapper = styled.nav`
  height: 90px;
  background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  /* box-shadow: ${({ scrollNav }) => (scrollNav ? "1px 1px 5px #5a5a5a" : "")};
  -webkit-transition: box-shadow 0.3s ease-in-out 0s;
  -moz-transition: box-shadow 0.3s ease-in-out 0s;
  -o-transition: box-shadow 0.3s ease-in-out 0s;
  -ms-transition: box-shadow 0.3s ease-in-out 0s;
  transition: box-shadow 0.3s ease-in-out 0s; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: fixed;
  padding: 10px 0;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

const NavbarLogo = styled(Link)`
  color: var(--white);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

const NavbarLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 70px;

  img {
    width: 100%;
    height: 100%;
    margin: 0 10px;
    border-radius: 50%;
  }
`;

const MobileIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--white);
  }
`;

const NavbarList = styled.ul`
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: -22px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavbarItem = styled.li`
  height: 80px;
  margin: 0 5px;
  font-size: 17px;

  #tooltip {
    position: relative;
    cursor: pointer;
  }
  #tooltipText {
    position: absolute;
    left: 50%;
    top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${({ isHovered }) => (isHovered ? "block" : "none")};
    color: #fff;
    padding: 10px;
    background-color: var(--black);
    border: 1px solid var(--blue);
    border-radius: 10px;
    z-index: 99;
  }
`;

const NavLinks = styled.a`
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid var(--blue);
  }

  &:active {
    border-bottom: 3px solid var(--blue);
  }
`;

const NavbarBtnWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  button {
    border: none;
    outline: none;
    font-size: 17px;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: var(--white);

    &:hover {
      background-color: var(--black);
      border: 1px solid var(--blue);
      transition: 0.3s all ease-in-out;
    }
  }
`;

export default Navbar;
