import React, { useState, useEffect } from "react";
import { ComingSoonPopup, Navbar, ScrollToTop, Sidebar } from "../components";
import {
  // About,
  Contact,
  Hero,
  Services,
  Protocols,
  Security,
} from "../sections";
import styled from "styled-components";
import scrollreveal from "scrollreveal";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        nav,
        #hero,
        #networks,
        #security,
        #services,
        #about,
        footer
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="We offer; easy, secure, reliable, non-custodial staking services."
        />
        <link rel="canonical" href="https://huginn.tech/" />
      </Helmet>
      <HomeBackground>
        <ScrollToTop />
        <Sidebar isOpen={isOpen} toggle={toggle} setOpenModal={setOpenModal} />
        <Navbar toggle={toggle} setOpenModal={setOpenModal} />
        <Hero />
        <Protocols />
        <Security />
        <Services />
        {/* <About /> */}
        <Contact />
        {openModal && <ComingSoonPopup onClose={() => setOpenModal(false)} />}
      </HomeBackground>
    </>
  );
};

const HomeBackground = styled.div`
  background-color: var(--black);
`;

export default Home;
