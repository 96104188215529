export const datas = [
  {
    title: "Cosmos Hub",
    mainnet: true,
    src: "https://wallet.keplr.app/chains/cosmos-hub?modal=validator&chain=cosmoshub-4&validator_address=cosmosvaloper1am8wmhwta4swx8f4quvffu7hqw48aujwypp0ew",
    img: require("../assets/protocols/cosmosHub.png"),
    apr: "",
  },
  {
    title: "DYMENSION",
    mainnet: true,
    src: "https://wallet.keplr.app/chains/dymension?modal=validator&chain=dymension_1100-1&validator_address=dymvaloper1thw8t8skc07sj5fyh9ggw4qachft6h8p9h4qwv",
    img: require("../assets/protocols/dym.png"),
    apr: "",
  },
  {
    title: "OmniFlix",
    mainnet: true,
    src: "https://wallet.keplr.app/chains/omniflix?modal=validator&chain=omniflixhub-1&validator_address=omniflixvaloper1nxhlmhaeme4j25d5vac6tk4wgy092xdsu77h4x&referral=true",
    img: require("../assets/protocols/omniflix.png"),
    apr: "",
  },
  {
    title: "UMEE",
    mainnet: true,
    src: "https://wallet.keplr.app/chains/umee?modal=validator&chain=umee-1&validator_address=umeevaloper1vr59ktf5yehvxz06qe889sl4eg0dtak8l2yge3&referral=true",
    img: require("../assets/protocols/umee.png"),
    apr: 38,
  },
  {
    title: "Humans",
    mainnet: true,
    src: "https://explorer.kjnodes.com/humans/staking/humanvaloper1tr853jvy38wn6pa05vsqtzt2mzrzpxxnazsmjn",
    img: require("../assets/protocols/humans.png"),
    apr: "",
    radius: true,
  },
  {
    title: "JACKAL",
    mainnet: true,
    src: "https://restake.app/jackal/jklvaloper1sxnq68tdscu5znhqn408av4vv3g9ka088l2s0j",
    img: require("../assets/protocols/jackal.png"),
    apr: "",
  },
  {
    title: "PASSAGE",
    mainnet: true,
    src: "https://www.mintscan.io/passage/validators/pasgvaloper1fmxyjxaadxyk2am4mphp089c4e94vyxwcdfefw",
    img: require("../assets/protocols/passage.png"),
    apr: "",
  },
  {
    title: "Quicksilver",
    mainnet: true,
    src: "https://wallet.keplr.app/chains/quicksilver?modal=validator&chain=quicksilver-2&validator_address=quickvaloper19wp3y9h3h4yz7teevse22zpxa74skahmd6q3z6&referral=true",
    img: require("../assets/protocols/quicksilver.png"),
    apr: "",
  },
  {
    title: "Gitopia",
    mainnet: true,
    src: "https://restake.app/gitopia/gitopiavaloper13w3z8f4emzuqtgda5crgyq6j4juayv4fdps57x/stake",
    img: require("../assets/protocols/gitopia.png"),
    apr: "",
    radius: true,
  },
  {
    title: "NYM",
    img: require("../assets/protocols/nym.png"),
    src: "https://mixnet.explorers.guru/mixnode/4hSn2aph7saUDDviw8NN1idBGHb1mjcPBy1PX4pKMce3",
    mainnet: true,
    apr: 42.3,
  },
  {
    title: "Pylons",
    mainnet: true,
    src: "https://pylons.explorers.guru/validator/pylovaloper16kkqetqwqps4slu2dw2sfslz7cvz8wgyj5ugpk",
    img: require("../assets/protocols/pylons.png"),
    apr: "",
  },
];
